import React from 'react'
import Card from './Card'
import { graphql } from 'gatsby'

import styles from './offices.module.scss'

export const locationFragment = graphql`
  fragment LocationFragment on ContentfulLocation {
    id
    title
    address {
      childMarkdownRemark {
        html
      }
    }
    location {
      lat
      lon
    }
    image {
      file {
        url
      }
    }
  }
`

const Offices = ({offices}) => {
  return (
    <div className={styles.offices}>
      {offices.map(({ node }) => {
        return (
          <div className={styles.offices__entry} key={node.id}>
            <Card spread tight>
              <Card.Content
                headline={node.title}
                imageUrl={node.image ? node.image.file.url : null}
                buttonLink={node.location ? `https://www.google.com/maps/dir/?api=1&destination=${node.location.lat},${node.location.lon}` : null}
                buttonText='Get directions'
                buttonProps={{
                  target: '_blank'
                }}
              >
                {node.address && (
                  <div
                    dangerouslySetInnerHTML={{ __html: node.address.childMarkdownRemark.html }}
                  />
                )}
              </Card.Content>
            </Card>
          </div>
        )
      })}
    </div>
  )
}

export default Offices
