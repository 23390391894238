import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import ContentfulPage from '../components/Page/ContentfulPage'
import SignUp from '../components/FS/SignUp'

import Offices from '../components/offices'
import withContentfulBlocks from '../components/withContentfulBlocks'

class ContactIndex extends React.Component {
  render () {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const offices = get(this.props, 'data.allContentfulLocation.edges')
    return (
      <ContentfulPage blocks={this.props.blocks} footerColor='white'>
        <Helmet title={`Contact ${siteTitle}`} />

        <Page.Panel noPadding color='grey'>
          <Offices offices={offices} />
        </Page.Panel>

        <SignUp />
      </ContentfulPage>
    )
  }
}

export default withContentfulBlocks(ContactIndex)

export const pageQuery = graphql`
  query FSContactQuery {
    site {
      siteMetadata {
        siteName
      }
    }
    page: contentfulPage(contentful_id: { eq: "5yhP8hsdWjpgJpG4slZo06" }) {
      ...PageBlocksFragment
    }
    allContentfulLocation(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          ...LocationFragment
        }
      }
    }
  }
`
